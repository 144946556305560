
export const formatToChileanRut = (rut) => {
    // Eliminar puntos y guiones
    const cleanRut = rut.replace(/[.-]/g, '');

    // Aislar cuerpo y dígito verificador
    const body = cleanRut.slice(0, cleanRut.length == 10 ?  -2 : -1);
    const dv = cleanRut.slice(cleanRut.length == 10 ? -2 : -1).toUpperCase();

    // Formatear el RUT con puntos y guión
    const rutFormatted = body.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '-' + dv;

    return rutFormatted;
}

export const validateRut = (rut) => {
    let cleanRut = rut.replace(/[.-]/g, '');

    cleanRut = cleanRut.length == 10 ? cleanRut.slice(0, -1) : cleanRut;

    // Validar formato: XX.XXX.XXX-Y
    const validFormat = /^(\d{1,2})\.(\d{3})\.(\d{3})-(\d|k)([a-zA-Z])?$/i.test(rut);
    if (!validFormat) {
        return false;
    }

    // Aislar cuerpo y dígito verificador
    const body = cleanRut.slice(0, -1);
    const dv = cleanRut.slice(-1).toUpperCase();

    // Calcular dígito verificador esperado
    const calculateDv = calculateDigitVerify(body);
    // Comparar con el dígito verificador proporcionado
    return dv === calculateDv;
}

function calculateDigitVerify(number) {
    let sum = 0;
    let mul = 2;
    let i = number.length;
    while (i--) {
        sum += parseInt(number.charAt(i)) * mul;
        if (mul % 7 === 0) {
            mul = 2;
        } else {
            mul++;
        }
    }
    const res = sum % 11;
    if (res === 0) {
        return '0';
    } else if (res === 1) {
        return 'K';
    }
    return `${11 - res}`;
}
